import request from '@/plugins/axios'
import * as Interface from '@/api/marketing/index.d'

/** S 营销主页 **/

// 获取营销模块
export const apiMarketingModule = () =>
  request.get('/config/getMarketingModule')

// // 获取应用模块
// 现在是营销模块和应用模块合二为一了
export const apiAppModule = () => request.get('/config/getAppModule')

/** E 营销主页 **/

/** S 足迹气泡 **/

// 获取足迹气泡配置及列表
export const apiFootprintList = (): Promise<Interface.FootprintList_Res> =>
  request.get('/footprint/index')

// 获取足迹气泡详情
export const apiFootprintDetail = (
  params: Interface.FootprintDetail_Req
): Promise<Interface.FootprintDetail_Res> =>
  request.get('/footprint/detail', { params })

// 编辑足迹气泡
export const apiFootprintEdit = (
  data: Interface.FootprintEdit_Req
): Promise<any> => request.post('/footprint/edit', data)

// 配置足迹气泡
export const apiFootprintSetting = (
  data: Interface.FootprintSetting_Req
): Promise<any> => request.post('/footprint/set', data)

/** E 足迹气泡 **/

// 获取活动
export const apiGetActivity = (params: any) =>
  request.get('/common/activity', { params })

// 获取活动商品
export const apiGetActivityGoods = (params: any) =>
  request.get('/common/activityGoods', { params })
