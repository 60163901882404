

































import { apiAppModule } from '@/api/marketing'
import { Component, Vue } from 'vue-property-decorator'
import config from '@/config'
@Component
export default class MarketingCenter extends Vue {
  menuList = []
  menuList2 = []

  getModule() {
    apiAppModule().then((res) => {
      res.map((item: any) => {
        return item.list.map((el: any) => {
          el.image = config.baseURL + el.image
          return el
        })
      })
      this.menuList = res
    })
  }

  created() {
    this.getModule()
  }
}
